/*
 * This project use mobile first integration
 * Default media is mobile
 */

const sizes = {
  mobile: 576,
  tablet: 768,
  desktop: 1200,
}

export default sizes
