import * as configuration from '../configuration'

type ApiResponse<DataType = any> = {
  error: boolean
  status: number
  data: DataType
}

export const fetchApi = async <ApiResponseDataType>(
  url: string,
  options: { method: string; data?: { [key: string]: any } }
): Promise<ApiResponse<ApiResponseDataType>> => {
  const formData = new FormData()

  if (options.data) {
    for (const name in options.data) {
      formData.append(name, options.data[name])
    }
  }

  const response = await fetch(`${configuration.api.url}/api/${url}`, {
    method: options.method || 'POST',
    headers: {
      Authorization: configuration.api.auth || '',
    },
    body: options.data ? formData : undefined,
  })

  return await response.json()
}
