import { createSlice, createSelector } from '@reduxjs/toolkit'

import { themesKey } from '../../theme'
import { Screen } from '../../types/global'
import * as configuration from '../../configuration'
import type { RootState } from '../store'

import { actionTypes } from './types'

type AppState = {
  isInit: boolean
  theme: themesKey
  screen: Screen
  isWebcamEnabled: boolean
  mainPhoto?: string
  flowersPhotos: {
    [index: number]: string
  }
  mainWithFlowersPhoto?: string
  finalPhotoLocal?: string
  finalPhotoOnline?: {
    url: string
    id: number
  }
  isPrinting: boolean
}

//
// Initial state
//

const initialState: AppState = {
  isInit: false,
  isWebcamEnabled: configuration.app.isPodium,
  theme: themesKey.theme1,
  screen: Screen.home,
  flowersPhotos: {},
  isPrinting: false,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    init: () => undefined,
    setIsInit: (state, action: actionTypes.setIsInit) => {
      const { isInit } = action.payload
      state.isInit = isInit
    },
    setTheme: (state, { payload }: actionTypes.setTheme) => {
      const { theme } = payload
      state.theme = theme
    },
    setScreen: (state, { payload }: actionTypes.setScreen) => {
      state.screen = payload.screen
    },
    setIsWebcamEnabled: (state, { payload }: actionTypes.setIsWebcamEnabled) => {
      state.isWebcamEnabled = payload.isEnabled
    },
    setMainPhoto: (state, { payload }: actionTypes.setMainPhoto) => {
      state.mainPhoto = payload.url
    },
    setFlowersPhoto: (state, { payload }: actionTypes.setFlowersPhoto) => {
      state.flowersPhotos[payload.index] = payload.url
    },
    resetFlowersPhotos: (state) => {
      state.flowersPhotos = {}
    },
    setMainWithFlowersPhoto: (state, { payload }: actionTypes.setMainWithFlowersPhoto) => {
      state.mainWithFlowersPhoto = payload.url
    },
    setFinalPhotoLocal: (state, { payload }: actionTypes.setFinalPhotoLocal) => {
      state.finalPhotoLocal = payload.url
    },
    setFinalPhotoOnline: (state, { payload }: actionTypes.setFinalPhotoOnline) => {
      state.finalPhotoOnline = payload
    },
    setIsPrinting: (state, { payload }: actionTypes.setIsPrinting) => {
      state.isPrinting = payload.value
    },
    printPhoto: (_state, _action: actionTypes.printPhoto) => undefined,
    start: () => undefined,
    restart: (state) => {
      state.mainPhoto = undefined
      state.flowersPhotos = {}
      state.mainWithFlowersPhoto = undefined
      state.finalPhotoLocal = undefined
      state.finalPhotoOnline = undefined
      state.screen = Screen.mainPhotoStep
    },
    reset: (state) => {
      state.mainPhoto = undefined
      state.flowersPhotos = {}
      state.mainWithFlowersPhoto = undefined
      state.finalPhotoLocal = undefined
      state.finalPhotoOnline = undefined
      state.screen = Screen.home
    },
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state.app
const isInit = (state: RootState) => root(state).isInit
const theme = (state: RootState) => root(state).theme
const screen = (state: RootState) => root(state).screen
const isWebcamEnabled = (state: RootState) => root(state).isWebcamEnabled
const mainPhoto = (state: RootState) => root(state).mainPhoto
const flowersPhotos = (state: RootState) => root(state).flowersPhotos
const flowersPhotosArray = createSelector([flowersPhotos], (flowers) => {
  const flowersArray: [string?, string?, string?] = []
  for (const flowerIndex in flowers) {
    flowersArray[flowerIndex] = flowers[flowerIndex]
  }
  return flowersArray
})
const mainWithFlowersPhoto = (state: RootState) => root(state).mainWithFlowersPhoto
const finalPhotoLocal = (state: RootState) => root(state).finalPhotoLocal
const finalPhotoOnline = (state: RootState) => root(state).finalPhotoOnline
const isPrinting = (state: RootState) => root(state).isPrinting

export const selectors = {
  isInit,
  theme,
  screen,
  isWebcamEnabled,
  mainPhoto,
  flowersPhotos,
  flowersPhotosArray,
  mainWithFlowersPhoto,
  finalPhotoLocal,
  finalPhotoOnline,
  isPrinting,
}
