import {
  all,
  call,
  put,
  takeLatest,
  takeLeading,
  select,
  SagaReturnType,
  delay,
} from 'redux-saga/effects'

import { actions, selectors } from '..'
import * as api from '../../api'
import * as configuration from '../../configuration'

import { actionTypes } from './types'

export default class AppSagas {
  static *init() {
    const isInit: ReturnType<typeof selectors.app.isInit> = yield select(selectors.app.isInit)

    if (!isInit) {
      yield put(actions.app.setIsInit({ isInit: true }))
    }
  }

  static *setTheme({ payload }: actionTypes.setTheme) {
    const theme: ReturnType<typeof selectors.app.theme> = yield select(selectors.app.theme)
    console.log('AppSagas - setTheme : ', { payload, theme })
  }

  static *setFinalPhotoLocal({ payload }: actionTypes.setFinalPhotoLocal) {
    // only for podium
    if (configuration.app.isPodium) {
      // store photo online -> get photo url
      const response: SagaReturnType<typeof api.asset.store> = yield call(api.asset.store, {
        image: payload.url,
        internal_id: (window as any)?.deviceId,
      })

      if (!response.error) {
        yield put(
          actions.app.setFinalPhotoOnline({ url: response.data.url, id: response.data.asset_id })
        )
      }
    }
  }

  static *printPhoto({ payload }: actionTypes.printPhoto) {
    yield put(actions.app.setIsPrinting({ value: true }))
    if (payload.url) {
      // To print photo, send data to react native app
      ;(window as any)?.ReactNativeWebView?.postMessage(
        JSON.stringify({ url: payload.url, id: payload.id })
      )
    }
    // wait to re-enable printing
    yield delay(8000)
    yield put(actions.app.setIsPrinting({ value: false }))
  }

  static *start() {
    const internalId = (window as any)?.deviceId
    yield call(api.experience.start, {
      internal_id: internalId || 'web',
    })
  }

  static *reset() {
    yield put(actions.inactivity.stopTimer())
  }

  static *loop() {
    yield all([
      takeLatest(actions.app.init, this.init),
      takeLatest(actions.app.setTheme, this.setTheme),
      takeLeading(actions.app.setFinalPhotoLocal, this.setFinalPhotoLocal),
      takeLeading(actions.app.printPhoto, this.printPhoto),
      takeLeading(actions.app.start, this.start),
      takeLeading(actions.app.reset, this.reset),
    ])
  }
}
