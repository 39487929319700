import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'

import { actionTypes } from './types'

type InactivityState = {
  timer: number
  isStarted: boolean
}

//
// Initial state
//

const initialState: InactivityState = {
  timer: 90,
  isStarted: false,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'inactivity',
  initialState,
  reducers: {
    initTimer: () => undefined,
    setIsStarted: (state, action: actionTypes.setIsStarted) => {
      const { isStarted } = action.payload
      state.isStarted = isStarted
    },
    setTimer: (state, action: actionTypes.setTimer) => {
      const { timer } = action.payload
      state.timer = timer
    },
    startTimer: () => undefined,
    stopTimer: () => undefined,
    resetTimer: () => undefined,
    decrementTimer: (state) => {
      state.timer -= 1
    },
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state.inactivity
const timer = (state: RootState) => root(state).timer
const isStarted = (state: RootState) => root(state).isStarted

export const selectors = {
  timer,
  isStarted,
}
