import { fetchApi } from './fetchApi'

export const store = async (data: { image: string; internal_id?: string }) => {
  return await fetchApi<{ url: string; asset_id: number }>('asset', {
    method: 'POST',
    data,
  })
}

export const print = async (data: { asset_id: string }) => {
  return await fetchApi('asset/print', {
    method: 'POST',
    data,
  })
}
