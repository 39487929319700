import {
  actions as appActions,
  selectors as appSelectors,
  reducer as appReducer,
} from './app/redux'
import {
  actions as inactivityActions,
  selectors as inactivitySelectors,
  reducer as inactivityReducer,
} from './inactivity/redux'

export const actions = {
  app: appActions,
  inactivity: inactivityActions,
}

export const selectors = {
  app: appSelectors,
  inactivity: inactivitySelectors,
}

export const reducers = {
  app: appReducer,
  inactivity: inactivityReducer,
}
