import fonts from './fonts'

const vnFont = `html[lang="vn"] & {
  ${fonts.Arial};
}`
const vnFontBold = `html[lang="vn"] & {
  ${fonts.ArialBold};
}`

const textStyles = {
  MontserratRegular14: `
    ${fonts.GucciSansBook}
    font-size: 1.4rem;
    line-height: normal;
    ${vnFont}
  `,
  MontserratRegular18Up: `
    ${fonts.GucciSansBook}
    font-size: 1.8rem;
    line-height: 1;
    text-transform: uppercase;
    ${vnFont}
  `,
  MontserratRegular24Up: `
    ${fonts.GucciSansBook}
    font-size: 2.4rem;
    line-height: 1;
    text-transform: uppercase;
    ${vnFont}
  `,
  MontserratRegular28Up: `
    ${fonts.GucciSansBook}
    font-size: 2.8rem;
    line-height: 1;
    text-transform: uppercase;
    ${vnFont}
  `,
  MontserratRegular32Up: `
    ${fonts.GucciSansBook}
    font-size: 3.2rem;
    line-height: 1;
    text-transform: uppercase;
    ${vnFont}
  `,
  MontserratRegular36Up: `
    ${fonts.GucciSansBook}
    font-size: 3.6rem;
    line-height: 1;
    text-transform: uppercase;
    ${vnFont}
  `,
  MontserratMedium12: `
    ${fonts.GucciSansBook}
    font-size: 1.2rem;
    ${vnFont}
  `,
  MontserratMedium16: `
    ${fonts.GucciSansBook}
    font-size: 1.6rem;
    line-height: 1;
    ${vnFont}
  `,
  MontserratMedium18Up: `
    ${fonts.GucciSansBook}
    font-size: 1.8rem;
    line-height: 1;
    text-transform: uppercase;
    ${vnFont}
  `,
  MontserratMedium24Up: `
    ${fonts.GucciSansBook}
    font-size: 2.4rem;
    line-height: 1;
    text-transform: uppercase;
    ${vnFont}
  `,
  MontserratMedium30Up: `
    ${fonts.GucciSansBook}
    font-size: 3rem;
    line-height: 100%;
    text-transform: uppercase;
    ${vnFont}
  `,
  MontserratMedium36Up: `
    ${fonts.GucciSansBook}
    font-size: 3.6rem;
    line-height: 4.4rem;
    text-transform: uppercase;
    ${vnFont}
  `,
  MontserratSemibold14Up: `
    ${fonts.GucciSansBold}
    font-size: 1.4rem;
    line-height: 1;
    text-transform: uppercase;
    ${vnFontBold}
  `,
  MontserratSemibold24Up: `
    ${fonts.GucciSansBold}
    font-size: 2.4rem;
    line-height: 1;
    text-transform: uppercase;
    ${vnFontBold}
  `,
  MontserratSemibold36: `
    ${fonts.GucciSansBold}
    font-size: 3.6rem;
    line-height: 1;
    ${vnFontBold}
  `,
}

export default textStyles
