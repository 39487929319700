import { all, fork } from 'redux-saga/effects'

// App
import AppSagas from './app/sagas'
// Inactivity
import InactivitySagas from './inactivity/sagas'

function* loop() {
  yield all([AppSagas.loop(), InactivitySagas.loop()])
}

export default function* rootSaga() {
  yield fork(loop)
}
