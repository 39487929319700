import { declareFont, defineFont, Font, FontStyles, FontWeights } from '../utils/FontUtils'

enum FontKeys {
  OpenSansRegular = 'OpenSansRegular',
  OpenSansBoldItalic = 'OpenSansBoldItalic',
  MontserratMedium = 'MontserratMedium',
  MontserratRegular = 'MontserratRegular',
  MontserratSemibold = 'MontserratSemibold',
  GucciSansBold = 'GucciSansBold',
  GucciSansBook = 'GucciSansBook',
  ArialBold = 'ArialBold',
  Arial = 'Arial',
}

export const declarations: { [key in FontKeys]: Font } = {
  OpenSansRegular: {
    basename: 'OpenSans-Regular',
    fontFamily: 'OpenSans Regular',
    fontWeight: FontWeights.regular,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  OpenSansBoldItalic: {
    basename: 'OpenSans-BoldItalic',
    fontFamily: 'OpenSans BoldItalic',
    fontWeight: FontWeights.bold,
    fontStyle: FontStyles.italic,
    fallback: 'sans-serif',
  },
  MontserratMedium: {
    basename: 'Montserrat-Medium',
    fontFamily: 'Montserrat Medium',
    fontWeight: FontWeights.medium,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  MontserratRegular: {
    basename: 'Montserrat-Regular',
    fontFamily: 'Montserrat Regular',
    fontWeight: FontWeights.regular,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  MontserratSemibold: {
    basename: 'Montserrat-Semibold',
    fontFamily: 'Montserrat Semibold',
    fontWeight: FontWeights.semiBold,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  GucciSansBold: {
    basename: 'GucciSans-Bold',
    fontFamily: 'GucciSans Bold',
    fontWeight: FontWeights.bold,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  GucciSansBook: {
    basename: 'GucciSans-Book',
    fontFamily: 'GucciSans Book',
    fontWeight: FontWeights.regular,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  ArialBold: {
    basename: 'ArialBold',
    fontFamily: 'ArialBold',
    fontWeight: FontWeights.regular,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  Arial: {
    basename: 'Arial',
    fontFamily: 'Arial',
    fontWeight: FontWeights.regular,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
}

export const fontsFaces = (): string => Object.values(declarations).map(declareFont).join(' ')

const fonts: { [key in FontKeys]: string } = {
  OpenSansRegular: defineFont(declarations.OpenSansRegular),
  OpenSansBoldItalic: defineFont(declarations.OpenSansBoldItalic),
  MontserratMedium: defineFont(declarations.MontserratMedium),
  MontserratRegular: defineFont(declarations.MontserratRegular),
  MontserratSemibold: defineFont(declarations.MontserratSemibold),
  GucciSansBold: defineFont(declarations.GucciSansBold),
  GucciSansBook: defineFont(declarations.GucciSansBook),
  ArialBold: defineFont(declarations.ArialBold),
  Arial: defineFont(declarations.Arial),
}

export default fonts
