const colors = {
  // boilerplate colors
  blue: '#3C49CB',
  red: '#FE576B',
  orange: '#FF895A',
  pureBlack: '#000',
  pureWhite: '#FFF',
  yellow: '#FFBB00',
  // custom colors
  turquoise: '#3AA59D',
  turquoiseLight: '#C4E4E3',
  pink: '#D592AA',
  pinkLigth: '#EAC8D4',
}

export default colors
